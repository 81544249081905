const navLinksWrap = document.querySelector(".nav-links-wrap");
const hamburger = document.querySelector("#hamb-icon");
const mobileHeader = document.querySelector(".mobile-header");
const closeModalBtn = document.querySelector(".close-modal");
const heroHireBtn = document.querySelector(".hero-hire-btn");
const heroViewPrBtn = document.querySelector(".hero-viewProjects-btn");
const aboutSec = document.querySelector("#about-sec");
const projectsSec = document.querySelector("#projects-sec");
const getInTouchSec = document.querySelector("#getInTouch-sec");
const hero = document.querySelector("#hero-sec");
const heroH3 = hero.querySelector("h3");
const heroH1 = hero.querySelector("h1");
const heroP = hero.querySelector("p");
const heroSpan = hero.querySelector("span");
const heroImg = document.querySelector(".hero-img");

window.addEventListener("load", () => {
  heroH3.style.opacity = "1";
  heroH3.style.transform = "translateX(0)";

  heroH1.style.opacity = "1";
  heroH1.style.transform = "translateX(0)";

  heroP.style.opacity = "1";
  heroP.style.transform = "translateX(0)";

  heroSpan.style.opacity = "1";
  heroSpan.style.transform = "translateX(0)";

  heroImg.style.opacity = "1";
  heroImg.style.transform = "translateX(0)";
});

navLinksWrap.addEventListener("click", (e) => {
  if (e.target.classList.contains("about")) {
    aboutSec.scrollIntoView({ behavior: "smooth" });
  }

  if (e.target.classList.contains("projects")) {
    projectsSec.scrollIntoView({ behavior: "smooth" });
  }

  if (e.target.classList.contains("hire")) {
    getInTouchSec.scrollIntoView({ behavior: "smooth" });
  }
});

mobileHeader.addEventListener("click", (e) => {
  if (e.target.classList.contains("about")) {
    aboutSec.scrollIntoView({ behavior: "smooth" });
    mobileHeader.style.display = "none";
  }

  if (e.target.classList.contains("projects")) {
    projectsSec.scrollIntoView({ behavior: "smooth" });
    mobileHeader.style.display = "none";
  }

  if (e.target.classList.contains("hire")) {
    getInTouchSec.scrollIntoView({ behavior: "smooth" });
    mobileHeader.style.display = "none";
  }
});

hamburger.addEventListener("click", () => {
  mobileHeader.style.display = "flex";
});

closeModalBtn.addEventListener("click", () => {
  mobileHeader.style.display = "none";
});

heroHireBtn.addEventListener("click", () => {
  getInTouchSec.scrollIntoView({ behavior: "smooth" });
});

heroViewPrBtn.addEventListener("click", () => {
  projectsSec.scrollIntoView({ behavior: "smooth" });
});
